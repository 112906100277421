<template>
    <CContainer>
        <CCard>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(submit)">
                    <CCardHeader>
                        <h4 class="m-0">{{ $t('pages.vdi.pools.headers.editPool') }} ({{ form.name }})</h4>
                    </CCardHeader>
                    <CCardBody>
                        <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                            <b-row>
                                <b-col lg="6">

                                    <validation-provider
                                        :name="$t('pages.vdi.pools.form.vmName')"
                                        :rules="{ required: true, min: 3, regex: /^[a-zA-Z0-9_-]*$/, max: 10 }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.vmName') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.vmName')"/>
                                            </template>
                                            <b-input-group>
                                                <b-form-input
                                                    type="text"
                                                    name="name"
                                                    :state="getValidationState(validationContext)"
                                                    v-model="form.name"
                                                    aria-describedby="name-feedback"
                                                />
                                                <b-input-group-append>
                                                    <b-input-group-text>-0000</b-input-group-text>
                                                </b-input-group-append>
                                                <b-form-invalid-feedback id="name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>
                                    </validation-provider>

                                    <validation-provider
                                        :name="$t('pages.vdi.pools.form.poolName', { language: $t('generally.languages.de') })"
                                        :rules="{ required: true, min: 3, max: 64 }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.poolName', {language: $t('generally.languages.de')}) }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.poolName')"/>
                                            </template>
                                            <b-form-input
                                                type="text"
                                                name="poolName-de"
                                                :state="getValidationState(validationContext)"
                                                v-model="form.publicName.de"
                                                aria-describedby="poolName-de-feedback"
                                            />
                                            <b-form-invalid-feedback id="poolName-de-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>

                                    <validation-provider
                                        :name="$t('pages.vdi.pools.form.poolName', { language: $t('generally.languages.en') })"
                                        :rules="{ required: true, min: 3, max: 64 }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.poolName', {language: $t('generally.languages.en')}) }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.poolName')"/>
                                            </template>
                                            <b-form-input
                                                type="text"
                                                name="poolName-en"
                                                :state="getValidationState(validationContext)"
                                                v-model="form.publicName.en"
                                                aria-describedby="poolName-en-feedback"
                                            />
                                            <b-form-invalid-feedback id="poolName-en-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>

                                    <validation-provider
                                        :name="$t('pages.vdi.pools.form.description', { language: $t('generally.languages.de') }).toString()"
                                        :rules="{ required: true, min: 3, max: 512 }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.description', { language: $t('generally.languages.de') }) }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.description')"/>
                                            </template>
                                            <b-form-textarea
                                                name="description-de"
                                                :state="getValidationState(validationContext)"
                                                v-model="form.description.de"
                                                aria-describedby="description-de-feedback"
                                                rows="4"
                                                max-rows="5"
                                            ></b-form-textarea>
                                            <b-form-invalid-feedback id="description-de-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <validation-provider
                                        :name="$t('pages.vdi.pools.form.description', { language: $t('generally.languages.en') }).toString()"
                                        :rules="{ required: true, min: 3, max: 512 }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.description', { language: $t('generally.languages.en') }) }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.description')"/>
                                            </template>
                                            <b-form-textarea
                                                name="description-en"
                                                :state="getValidationState(validationContext)"
                                                v-model="form.description.en"
                                                aria-describedby="description-en-feedback"
                                                rows="4"
                                                max-rows="5"
                                            ></b-form-textarea>
                                            <b-form-invalid-feedback id="description-en-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>



                                </b-col>
                                <b-col lg="6">

                                    <validation-provider
                                        :name="$t('pages.vdi.pools.form.image')"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                            label-cols-lg="5"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.image') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.image')"/>
                                            </template>
                                            <b-form-select
                                                v-model="form.imageId"
                                                :options="images"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="image-feedback"
                                            ></b-form-select>
                                            <b-form-invalid-feedback id="image-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                   
                                    <b-form-group
                                        label-cols-sm="12"
                                        label-cols-md="6"
                                        label-cols-lg="5"
                                    >
                                        <template #label>
                                            {{ $t('pages.vdi.pools.form.datastore') }}
                                            <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.datastore')"/>
                                        </template>
                                        <b-form-select v-model="form.datastoreId" :options="datastores"></b-form-select>
                                    </b-form-group>
                                   
                                    <b-form-group
                                        label-cols-sm="12"
                                        label-cols-md="6"
                                        label-cols-lg="5"
                                    >
                                        <template #label>
                                            {{ $t('pages.vdi.pools.form.disabled') }}
                                            <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.disabled')"/>
                                        </template>
                                        <b-form-select v-model="form.disabled" :options="disableOptions"></b-form-select>
                                    </b-form-group>

                                    <b-form-group
                                        label-cols-sm="12"
                                        label-cols-md="6"
                                        label-cols-lg="5"
                                    >
                                        <template #label>
                                            {{ $t('pages.vdi.pools.form.public') }}
                                            <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.public')"/>
                                        </template>
                                        <b-form-select v-model="form.public">
                                            <b-form-select-option :value="false">{{ $t('pages.vdi.pools.values.public.no') }}</b-form-select-option>
                                            <b-form-select-option :value="true">{{ $t('pages.vdi.pools.values.public.yes') }}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                            
                                    <validation-provider
                                        :name="$t('pages.vdi.pools.form.max')"
                                        :rules="{ required: true, min: 1, max: 2, numeric: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.max') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.max')"/>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                name="max"
                                                v-model="form.max"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="max-feedback"
                                                min="0"
                                                max="99"
                                            />
                                            <b-form-invalid-feedback id="max-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                                        </b-form-group>
                                    </validation-provider>

                                    <validation-provider
                                        :name="$t('pages.vdi.pools.form.min')"
                                        :rules="{ required: true, min: 0, max: form.max, numeric: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.min') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.min')"/>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                name="min"
                                                v-model="form.min"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="min-feedback"
                                                :max="form.max"
                                                min="0"
                                            />
                                            <b-form-invalid-feedback id="min-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                                        </b-form-group>
                                    </validation-provider>

                                    <validation-provider
                                        :name="$t('pages.vdi.pools.form.minActiveSessionTime')"
                                        :rules="{ required: true, min: 1, max: 240, numeric: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.minActiveSessionTime') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.minActiveSessionTime')"/>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                name="min"
                                                v-model="form.minActiveSessionTime"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="minActiveSessionTime-feedback"
                                                :max="240"
                                                min="1"
                                            />
                                            <b-form-invalid-feedback id="minActiveSessionTime-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                                        </b-form-group>
                                    </validation-provider>

                                    <validation-provider
                                        v-if="form.type === 'static'"
                                        :name="$t('pages.vdi.pools.form.minStaticAssociationDays')"
                                        :rules="{ required: true, min: 1, max: 14, numeric: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols-sm="12"
                                            label-cols-md="6"
                                        >
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.minStaticAssociationDays') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.minStaticAssociationDays')"/>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                name="min"
                                                v-model="form.minStaticAssociationDays"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="minStaticAssociationDays-feedback"
                                                :max="240"
                                                min="5"
                                            />
                                            <b-form-invalid-feedback id="minStaticAssociationDays-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                                        </b-form-group>
                                    </validation-provider>

                                </b-col>
                            </b-row>

                            <b-form-group>
                                <template #label>
                                    {{ $t('pages.vdi.pools.form.vmConfig') }}
                                    <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.vmConfig')"/>
                                </template>
                                <vue-codeditor style="font-size: 14px" v-model="form.vmConfig" mode="json" theme="monokai"/>
                            </b-form-group>

                            <b-form-group>
                                <template #label>
                                    {{ $t('pages.vdi.pools.form.vmInfoTemplate') }} (<CLink @click="editorToggle = !editorToggle"><font-awesome-icon icon="code"/></CLink>)
                                </template>
                                <vue-editor v-if="editorToggle" v-model="form.vmInfoTemplate"></vue-editor>
                                <vue-codeditor v-else style="font-size: 14px" v-model="form.vmInfoTemplate" mode="html" theme="monokai"/>
                            </b-form-group>

                            <b-button v-b-toggle.extended class="m-1" size="sm" variant="default">{{ $t('pages.vdi.pools.form.extended') }}
                                <font-awesome-icon icon="caret-down"/>
                            </b-button>
                            <b-collapse id="extended">
                                <CCard>
                                    <CCardBody>
                                      
                                        <b-form-group>
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.codeType') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.codeType')"/>
                                            </template>
                                            <b-form-select
                                                v-model="form.codeType" >
                                                <b-form-select-option value="powershell">PowerShell</b-form-select-option>
                                                <b-form-select-option value="sh">Bash</b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>

                                        <b-form-group>
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.validateScriptResult') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.validateScriptResult')"/>
                                            </template>
                                            <b-form-select v-model="form.validateScriptResult" :options="validateScriptResultOptions">
                                            </b-form-select>
                                        </b-form-group>

                                        <b-form-group>
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.scriptCode') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.scriptCode')"/>
                                            </template>
                                            <vue-codeditor style="font-size: 14px" v-model="form.scriptCode" :mode="form.codeType" theme="monokai"/>
                                        </b-form-group>

                                        <b-form-group>
                                            <template #label>
                                                {{ $t('pages.vdi.pools.form.rdpTemplate') }}
                                                <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.vdi.pools.form.hints.rdpTemplate')"/>
                                            </template>
                                            <vue-codeditor style="font-size: 14px" v-model="form.rdpTemplate" mode="eiffel" theme="monokai"/>
                                        </b-form-group>
                                        <b-form-group>
                                            {{ $t('pages.vdi.pools.settings.variables.defaultVariables.description') }}:<br>
                                            <ul>
                                                <li>{userName} = {{ $t('pages.vdi.pools.settings.variables.defaultVariables.username') }}</li>
                                                <li>{localUserName} = {{ $t('pages.vdi.pools.settings.variables.defaultVariables.localUsername') }}</li>
                                                <li>{localPassword} = {{ $t('pages.vdi.pools.settings.variables.defaultVariables.localPassword') }}</li>
                                                <li>{host} = {{ $t('pages.vdi.pools.settings.variables.defaultVariables.host') }}</li>
                                            </ul>
                                        </b-form-group>

                                        <b-form-group
                                            v-for="(val, key) in form.rdpTemplateVariables"
                                            :key="key"
                                            label-cols-sm="12"
                                            label-cols-md="7"
                                            label-size="sm"
                                            :label="val[getLocale()]"
                                        >
                                            <b-input-group size="sm">
                                                <b-form-input
                                                    :name="val.name"
                                                    size="sm"
                                                    v-model="val.value"
                                                ></b-form-input>
                                                <b-input-group-append>
                                                    <b-input-group-text>
                                                        {{ val.name }}
                                                    </b-input-group-text>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>

                                        <CLink href="https://docs.microsoft.com/de-de/windows-server/remote/remote-pool-services/clients/rdp-files" target="_blank">
                                            <b-icon-box-arrow-up-right/>
                                            {{ $t('pages.vdi.apps.form.externalDescription') }}
                                        </CLink>

                                    </CCardBody>
                                </CCard>
                            </b-collapse>
                        </b-overlay>
                    </CCardBody>
                    <CCardFooter class="d-flex justify-content-end">
                        <b-button type="submit" variant="orange">{{ $t('buttons.save') }}</b-button>
                    </CCardFooter>
                </b-form>
            </validation-observer>
        </CCard>
    </CContainer>
</template>

<script>
import poolService from "../../../services/poolService";
import imagesService from "../../../services/imagesService";
import datastoresService from "../../../services/datastoresService";
import {VueEditor} from "vue2-editor";

export default {
    name: "Edit",
    props: ["id"],
    data: () => ({
        images: [],
        datastores: [],
        editorToggle: false,
        form: {
            name: '',
            publicName: {
                de: '',
                en: ''
            },
            description: {
                de: '',
                en: ''
            },
            max: 10,
            min: 2,
            disabled: false,
            minActiveSessionTime: 30,
            minStaticAssociationDays: 6,
            public: false,
            rdpTemplate: '',
            rdpTemplateVariables: [],
            codeType: 'powershell',
            scriptCode: '',
            validateScriptResult: true,
            vmInfoTemplate: '',
            vmConfig: '',
            datastoreId: '',
            imageId:''
        },
        disableOptions: [],
        validateScriptResultOptions: [],
        loading: false
    }),
    components: { VueEditor },
    mounted() {
        this.loadImages();
        this.loadDatastores();
        this.disableOptions = [
            {
                value: true,
                text: this.$t('states.inactiv')
            }, {
                value: false,
                text: this.$t('states.activ')
            },
        
        ];
        this.validateScriptResultOptions = [
            {
                value: true,
                text: this.$t('generally.yes')
            }, {
                value: false,
                text: this.$t('generally.no')
            },
        
        ];
        this.load();
    },
    methods: {
        load(){
            this.loading = true;
            poolService.get(this.id).then(res => {
                this.form = res.data;
                //this.form.rdpTemplateVariables = JSON.parse(res.data.rdpTemplateVariables);
                this.form.publicName = res.data.publicName ? JSON.parse(res.data.publicName) : this.form.publicName;
                this.form.description = res.data.description ? JSON.parse(res.data.description) : this.form.description;
                this.form.vmConfig = JSON.stringify(res.data.vmConfig, Object.keys(res.data.vmConfig).sort(), 2);
                this.loading = false;
            }).catch(error => {
                this.errorHandler(error);
                this.loading = false;
            });
        },
        getLocale() {
            return localStorage.getItem("locale");
        },
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        loadDatastores() {
            datastoresService.list().then(response => {
                this.datastores = response.data.map(i => ({value: i.id, text: `${i.name} [${i.vCenterDatastoreType}]`}));
            }).catch();
        },
        loadImages() {
            imagesService.list().then(response => {
                this.images = response.data.map(i => ({value: i.id, text: i.name}));
            }).catch();
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                let data = Object.assign({}, this.form);
                data.publicName = JSON.stringify(this.form.publicName);
                data.description = JSON.stringify(this.form.description);
                data.min = parseInt(data.min);
                data.max = parseInt(data.max);
                data.minActiveSessionTime = parseInt(data.minActiveSessionTime);
                data.minStaticAssociationDays = parseInt(data.minStaticAssociationDays);
                //data.rdpTemplateVariables = JSON.stringify(data.rdpTemplateVariables);
                data.vmConfig = JSON.parse(data.vmConfig);
                data.datastoreId = data.datastoreId || undefined;
                poolService.edit(this.id, data).then(() => {
                    this.$toastr.s(this.$t('pages.vdi.pools.messages.success.created'));
                    this.$router.push({name: 'VdiPools'});
                    this.loading = false;
                }).catch(error => {
                    this.errorHandler(error);
                    this.loading = false;
                });
            }
        }
    }
};
</script>

